import { Box, Button } from '@mui/material';
import React from 'react';

interface AdvantageExpandButtonProps {
  onClick: () => void;
  isExpanded: boolean;
}

enum ButtonLabel {
  ExpandAll = 'Rozwiń wszystkie korzyści',
  CollapseAll = 'Zwiń wszystkie korzyści'
}

export const AdvantagesExpandButton: React.FC<AdvantageExpandButtonProps> = ({ onClick, isExpanded }) => {
  const label = isExpanded ? ButtonLabel.CollapseAll : ButtonLabel.ExpandAll;
  return (
    <Box mt={5} display='flex' justifyContent='center'>
      <Button sx={{ minWidth: '60%' }} onClick={onClick}>
        {label}
      </Button>
    </Box>
  );
};
