import React from 'react';
import PageSection from '../../../components/Sections/PageSection';
import PageSectionTitle from '../../../components/Sections/PageSectionTitle';
import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { SectionItem } from './DataMigrationSectionItem';

interface CennikDataMigrationSectionProps {
  systems: string[];
  data: string[];
}

const CennikDataMigrationSection: React.FC<CennikDataMigrationSectionProps> = ({ data, systems }) => {
  const theme = useTheme();

  const isSmallerThanMd = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <PageSection maxWidth='lg'>
      <PageSectionTitle>Migracja danych</PageSectionTitle>
      <Typography variant='body1'>
        Jeśli dane do migracji zostaną przygotowane w formacie CSV zgodnie z wymaganiami Mediporty, jesteśmy w stanie
        zaimportować je z niemal wszystkich systemów dostępnych na rynku. Zakres danych, które migrujemy, jest również
        bardzo szeroki.
      </Typography>

      <Stack
        direction={isSmallerThanMd ? 'column' : 'row'}
        mt={4}
        gap={isSmallerThanMd ? 3 : 0}
        justifyContent={'space-evenly'}
      >
        <SectionItem title='Systemy, z których migrujemy dane:' items={systems} />
        <SectionItem title='Rodzaje danych, które migrujemy:' items={data} />
      </Stack>
    </PageSection>
  );
};

export default CennikDataMigrationSection;
