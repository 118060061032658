import { Grid, Typography, Stack } from '@mui/material';
import React from 'react';
import PageSection from '../../components/Sections/PageSection';
import CheckIcon from '@mui/icons-material/Check';

const Profit: React.FC<{ text: string }> = ({ text }) => (
  <Grid item xs={12} lg={6}>
    <Stack alignItems={'start'} direction={'row'} gap={2}>
      <CheckIcon color='info' sx={{ marginTop: '1px' }} />
      {text}
    </Stack>
  </Grid>
);

interface CennikImplementationProfitsSectionProps {
  profits: string[];
}

export const CennikImplementationProfitsSection: React.FC<CennikImplementationProfitsSectionProps> = ({ profits }) => {
  return (
    <PageSection maxWidth='lg' bgcolor='background.default' id='kontakt'>
      <Typography textTransform='uppercase' fontWeight={700} fontSize={'2rem'} mb={3}>
        Dzięki wdrożeniu systemu Mediporta zyskasz
      </Typography>
      <Grid container spacing={3}>
        {profits.map((profit) => (
          <Profit key={profit} text={profit} />
        ))}
      </Grid>
    </PageSection>
  );
};
