import { ListItem, Typography } from '@mui/material';
import React from 'react';

export const SectionListItem: React.FC<{ text: string }> = ({ text }) => (
  <ListItem
    sx={{
      display: 'list-item'
    }}
  >
    <Typography component='span' variant='body2' color={'primary.main'}>
      {text}
    </Typography>
  </ListItem>
);
