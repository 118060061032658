import { Stack, Typography, List } from '@mui/material';
import React from 'react';
import { SectionListItem } from './DataMigrationSectionListItem';

interface SectionItemProps {
  title: string;
  items: string[];
}

export const SectionItem: React.FC<SectionItemProps> = ({ title, items }) => (
  <Stack>
    <Typography variant='subtitle1' component={'h3'} fontWeight={600}>
      {title}
    </Typography>
    <List
      sx={{
        listStyleType: 'disc',
        listStylePosition: 'inside',
        color: 'text.secondary'
      }}
      dense
    >
      {items.map((system) => (
        <SectionListItem key={system} text={system} />
      ))}
    </List>
  </Stack>
);
