import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface AdvantageItemProps {
  title: string;
  description: string;
  isExpanded: boolean;
  onChange: (expandState: boolean) => void;
}

export const AdvantageItem: React.FC<AdvantageItemProps> = ({ description, isExpanded, title, onChange }) => {
  return (
    <Box sx={{ transitionDuration: '0s !important' }}>
      <Accordion variant='outlined' onChange={(_, state) => onChange(state)} expanded={isExpanded} disableGutters>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>{title}</AccordionSummary>
        <AccordionDetails>
          <Typography>{description}</Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
