import { BoxProps, Typography } from '@mui/material';
import React from 'react';
import PageSection from './PageSection';
import PageSectionTitle from './PageSectionTitle';
import FormularzKontaktowy from '../../sections/kontakt/formularz';

type ContactFormProps = {
  bgcolor: BoxProps['bgcolor'];
};

export const ContactForm: React.FC<ContactFormProps> = ({ bgcolor }) => {
  return (
    <PageSection maxWidth='lg' bgcolor={bgcolor} id='formularz-kontaktowy'>
      <PageSectionTitle>Formularz kontaktowy</PageSectionTitle>
      <Typography variant='h5' component={'h3'} marginBottom={5}>
        Jeśli chcesz skorzystać z naszych usług lub masz pytania, wypełnij poniższy formularz.
      </Typography>
      <FormularzKontaktowy />
    </PageSection>
  );
};
