import React from 'react';
import { ButtonProps, Card, CardActions, CardContent, Typography } from '@mui/material';
import { Button } from 'gatsby-material-ui-components';

export interface PricingTypeCard {
  title: string;
  image: React.ReactElement;
  selected: boolean;
  onSelect: ButtonProps['onClick'];
}

export default (props: PricingTypeCard) => (
  <Card
    elevation={0}
    sx={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: props.selected ? 'primary.main' : null,
      color: props.selected ? 'primary.contrastText' : null,
      a: { color: props.selected ? 'primary.contrastText' : null }
    }}
  >
    {props.image}
    <CardContent sx={{ flexGrow: 1, alignItems: 'end' }}>
      <Typography variant='h4' component={'h3'} textTransform='uppercase'>
        {props.title}
      </Typography>
    </CardContent>
    <CardActions
      sx={{
        padding: 2,
        // Domyślna reguła korzysta z :first-of-type a tutaj mamy <button> i <a>.
        '> :not(:first-child)': { marginLeft: 1 }
      }}
    >
      <Button fullWidth to='#oferty' disabled={props.selected} onClick={props.onSelect}>
        Wybierz
      </Button>
    </CardActions>
  </Card>
);
